<template>
  <ValidationObserver
    v-if="editing"
    v-slot="{ invalid }"
    tag="Form"
    inline
    @submit.native.prevent="updateMaxCapacity"
    class="flex items-center"
    :disabled="loading"
  >
    <ValidationProvider
      slim
      :name="`&quot;${$t('MAX_CAPACITY')}&quot;`"
      rules="required|numeric"
      immediate
      v-slot="{ pristine, errors }"
    >
      <FormItem
        class="flex-grow"
        :class="{ 'm-0': pristine || !errors[0] }"
        :error="pristine ? '' : errors[0]"
      >
        <Input v-model="value" type="number" number />
      </FormItem>
    </ValidationProvider>
    <Button
      type="text"
      html-type="submit"
      size="small"
      :disabled="invalid || value === maxCapacity"
      :loading="loading"
    >
      <fa icon="save" />
    </Button>
    <Button type="text" size="small" @click="reset">
      <fa icon="times" />
    </Button>
  </ValidationObserver>
  <div v-else>
    <span class="text-base">
      {{ maxCapacity }}
    </span>
    <Button type="text" size="small" class="align-top" @click="edit">
      <fa icon="pen" />
    </Button>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate-full";
import gql from "graphql-tag";

export default {
  components: { ValidationObserver, ValidationProvider },
  props: { maxCapacity: Number, name: String },
  inject: ["organization"],
  data() {
    return { editing: false, loading: false, value: this.maxCapacity };
  },
  methods: {
    edit() {
      this.reset();
      this.editing = true;
    },
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    updateMaxCapacity() {
      this.loading = true;
      const { name, value: newCapacity } = this;
      const { id, quotas } = this.organization;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation($tenantId: ID!, $name: String!, $newCapacity: Int!) {
              quotaChangeMaxCapacity(
                tenantId: $tenantId
                name: $name
                newCapacity: $newCapacity
              ) {
                id
                maxCapacity
                remaining
              }
            }
          `,
          variables: { tenantId: id, name, newCapacity }
        })
        .then(({ data: { quotaChangeMaxCapacity } }) => {
          const quotaIndex = quotas.findIndex(quota => quota.name === name);
          Object.assign(quotas[quotaIndex], quotaChangeMaxCapacity);
        })
        .catch(({ graphQLErrors, networkError }) =>
          this.$Notice.error({
            title: this.$t("ERROR"),
            desc: JSON.stringify(networkError || graphQLErrors),
            duration: 0
          })
        )
        .finally(this.reset);
    }
  }
};
</script>

<style scoped></style>
