<template>
  <div class="quotas-grid">
    <Card
      v-for="{
        id,
        name,
        maxCapacity,
        nextResetDate,
        ...rest
      } in organization.quotas"
      :key="id"
      dis-hover
    >
      <div class="flex">
        <div class="p-2 border-r border-gray-400 flex items-start">
          <img
            class="w-8 inline-block"
            :src="require(`@/assets/${QUOTAS[name].productIcon}`)"
            alt="Product logo"
          />
        </div>
        <div class="p-2 flex-grow">
          <div class="font-bold mb-3">
            {{ $t(QUOTAS[name].labelKey) }}
          </div>
          <div class="font-serif text-gray-500 lowercase small-caps">
            {{ $t("REMAINING_EMAILS") }}
          </div>
          <ConsumptionEditor
            v-bind="{ name, maxCapacity, ...rest }"
            :icon="QUOTAS[name].quotaIcon"
            class="text-base leading-normal"
          />
          <div class="font-serif text-gray-500 lowercase small-caps pt-2">
            {{ $t("MAX_CAPACITY") }}
          </div>
          <MaxCapacityEditor v-bind="{ name, maxCapacity, ...rest }" />
          <div class="font-serif text-gray-500 lowercase small-caps pt-2">
            {{ $t("NEXT_RENEWAL") }}
          </div>
          <span class="text-base">
            {{ new Date(nextResetDate).toLocaleDateString() }}
          </span>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import { QUOTAS } from "@/assetsMapping.const.js";
import ConsumptionEditor from "./components/ConsumptionEditor/ConsumptionEditor.vue";
import MaxCapacityEditor from "./components/MaxCapacityEditor/MaxCapacityEditor.vue";

export default {
  components: { ConsumptionEditor, MaxCapacityEditor },
  inject: ["organization"],
  computed: { QUOTAS: () => QUOTAS }
};
</script>

<style scoped>
.quotas-grid {
  @apply py-6 px-20;
  display: grid;
  grid-template-columns: repeat(auto-fill, 450px);
  grid-gap: 2rem;
  justify-content: space-around;
}
</style>
