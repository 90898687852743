function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quotas-grid"},_vm._l((_vm.organization.quotas),function(ref){
    var id = ref.id;
    var name = ref.name;
    var maxCapacity = ref.maxCapacity;
    var nextResetDate = ref.nextResetDate;
    var rest$1 = objectWithoutProperties( ref, ["id", "name", "maxCapacity", "nextResetDate"] );
    var rest = rest$1;
return _c('Card',{key:id,attrs:{"dis-hover":""}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"p-2 border-r border-gray-400 flex items-start"},[_c('img',{staticClass:"w-8 inline-block",attrs:{"src":require(("@/assets/" + (_vm.QUOTAS[name].productIcon))),"alt":"Product logo"}})]),_c('div',{staticClass:"p-2 flex-grow"},[_c('div',{staticClass:"font-bold mb-3"},[_vm._v(" "+_vm._s(_vm.$t(_vm.QUOTAS[name].labelKey))+" ")]),_c('div',{staticClass:"font-serif text-gray-500 lowercase small-caps"},[_vm._v(" "+_vm._s(_vm.$t("REMAINING_EMAILS"))+" ")]),_c('ConsumptionEditor',_vm._b({staticClass:"text-base leading-normal",attrs:{"icon":_vm.QUOTAS[name].quotaIcon}},'ConsumptionEditor',Object.assign({}, {name: name, maxCapacity: maxCapacity}, rest),false)),_c('div',{staticClass:"font-serif text-gray-500 lowercase small-caps pt-2"},[_vm._v(" "+_vm._s(_vm.$t("MAX_CAPACITY"))+" ")]),_c('MaxCapacityEditor',_vm._b({},'MaxCapacityEditor',Object.assign({}, {name: name, maxCapacity: maxCapacity}, rest),false)),_c('div',{staticClass:"font-serif text-gray-500 lowercase small-caps pt-2"},[_vm._v(" "+_vm._s(_vm.$t("NEXT_RENEWAL"))+" ")]),_c('span',{staticClass:"text-base"},[_vm._v(" "+_vm._s(new Date(nextResetDate).toLocaleDateString())+" ")])],1)])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }