<template>
  <Form
    v-if="editing"
    inline
    @submit.native.prevent="updateConsumption"
    class="flex items-center"
    :disabled="loading"
  >
    <FormItem class="m-0 flex-grow">
      <Slider v-model="value" :max="maxCapacity" show-input />
    </FormItem>
    <Button
      type="text"
      html-type="submit"
      size="small"
      :disabled="value === remaining"
      :loading="loading"
    >
      <fa icon="save" />
    </Button>
    <Button type="text" size="small" @click="reset">
      <fa icon="times" />
    </Button>
  </Form>
  <div class="flex" v-else>
    <div class="flex-grow">
      <Progress :percent="(remaining / maxCapacity) * 100 || 0" hide-info />
      <fa :icon="icon" class="mr-1"></fa>
      <span>{{ `${remaining} / ${maxCapacity}` }}</span>
    </div>
    <Button
      type="text"
      size="small"
      class="ml-2 align-super"
      @click="edit"
      :disabled="!maxCapacity"
    >
      <fa icon="pen" />
    </Button>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  props: { name: String, icon: String, maxCapacity: Number, remaining: Number },
  inject: ["organization"],
  data() {
    return { editing: false, loading: false, value: this.remaining };
  },
  methods: {
    edit() {
      this.reset();
      this.editing = true;
    },
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    updateConsumption() {
      this.loading = true;
      const { name, remaining, value } = this;
      const { id, quotas } = this.organization;
      const mutation = remaining < value ? "quotaRefill" : "quotaConsumeByName";
      const amount = remaining < value ? value - remaining : remaining - value;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation($tenantId: ID!, $name: String!, $amount: Int!) {
              ${mutation}(
                tenantId: $tenantId
                name: $name
                amount: $amount
              ) {
                id
                remaining
              }
            }
          `,
          variables: { tenantId: id, name, amount }
        })
        .then(({ data }) => {
          const { remaining } = data[mutation];
          const quotaIndex = quotas.findIndex(quota => quota.name === name);
          quotas[quotaIndex].remaining = remaining;
        })
        .catch(({ graphQLErrors, networkError }) =>
          this.$Notice.error({
            title: this.$t("ERROR"),
            desc: JSON.stringify(networkError || graphQLErrors),
            duration: 0
          })
        )
        .finally(this.reset);
    }
  }
};
</script>

<style scoped></style>
